<template>
    <ComplexDialog
        :title="title"
        v-model="visible"
        :loading="busy"
    >
      <v-window>
        <v-window-item>
          <p class="subtitle-1 text--primary">
            Contact Details
          </p>
          <ContactDetailsForm
              v-model="contact"
              :contactData="null"
          />
          <p class="subtitle-1 text--primary">
            User Details
          </p>
          <ConvertToUserDetailsForm
            v-model="user"
            :prefill="{'id':null,'username':null}"
            show-bcc
            hideEmailToggle
          />
          <v-form v-on:submit.prevent="">
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-checkbox
                  class="mt-0"
                  v-model="super_admin"
                  :label="'Is Super Admin'"
                  outlined
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-window-item>
      </v-window>
        <template v-slot:actions>
            <v-spacer />
            <v-btn
                text
                color="primary"
                :loading="busy"
                @click="save"
                :disabled="(contact == null || user == null)"
            >Save New Admin User</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
import ContactDetailsForm from "../../pieces/Forms/ContactDetailsForm.vue";
import ComplexDialog from "../templates/ComplexDialog.vue";
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from "../../../mixins/apiMixin";
import ConvertToUserDetailsForm from "../../pieces/Forms/ConvertToUserDetailsForm.vue";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
    export default {
        name: 'AddAdminUserDialog',
        mixins: [apiMixin, dialogMixin, GlobalHelperMixin],
        components: {
          ConvertToUserDetailsForm,
          ComplexDialog,
          ContactDetailsForm,
        },
        data: () => ({
          step: 1,
          busy: false,
          user:null,
          contact:null,
          super_admin:false,
        }),
        methods: {
            async save() {
                this.busy = true;
                let data = {
                  "contact":this.contact,
                  "user":this.user,
                  "super_admin":this.super_admin
                };
                let response = await this.Api.send('post', 'admin/users/admin', data);

                if (response.success) {
                  this.showGlobalSuccessMessage('New Contact created successfully.');
                  this.$router.push({name: 'AdminUserDetails',params:{userId:response.data.id}});
                  this.visible = false;
                }
                this.busy = false;
            }
        },
      watch: {

      },
      computed: {
        isSuperAdmin: function(){
          return this.$store.state.user.user_type == 'admin' && this.$store.state.user.user_roles && this.$store.state.user.user_roles.includes('super-admin');
        }
      },
      props: {
        title: {
            type: String,
            default: 'Add New Admin User'
        },
      },
    };
</script>
