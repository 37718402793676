<template>
    <BasicCard
        :loading="loading || busy"
        :no-skeleton="!!data"
    >
        <template v-slot:feature>
            <v-chip class="error ml-2" v-if="data.deleted_at">Deleted</v-chip>
            <v-chip class="warning ml-2" v-if="!data.deleted_at && data.active_at">Active</v-chip>
            <v-chip class="default ml-2" v-if="!data.deleted_at && !data.active_at">Inactive</v-chip>
            <v-chip class="primary ml-2">User</v-chip>
            <v-icon right x-large>mdi-account</v-icon>
        </template>
        <p class="title text--primary mt-1" v-if="data.username">{{ data.username }}</p>
        <v-row dense v-for="(value, name) in data" :key="name">
          <v-col cols="5">
            <span class="font-weight-bold">{{titleCase(name)}}</span>
          </v-col>
          <v-col cols="7">
            <span v-if="Array.isArray(value)">
              {{ value.join(", ") }}
            </span>
            <span v-else>{{ value }}</span>
          </v-col>
        </v-row>
      <template v-slot:actions v-if="!data.deleted_at">
        <ButtonWithTooltip
          v-if="data.user_type  == 'admin' && isSuperAdmin && data.user_roles && data.user_roles.includes('super-admin')"
          icon="mdi-shield"
          text="Remove Super Admin Role"
          flat
          @click="removeSuperAdmin()"
        />
        <ButtonWithTooltip
          v-if="data.user_type  == 'admin' && isSuperAdmin && (!data.user_roles || !data.user_roles.includes('super-admin'))"
          icon="mdi-shield-outline"
          text="Add Super Admin Role"
          flat
          @click="addSuperAdmin()"
        />
        <ButtonWithTooltip
          v-if="data.user_type  == 'admin'"
          icon="mdi-pencil"
          text="Edit Username"
          flat
          @click="editAdminUser()"
        />
        <ButtonWithTooltip
          v-if="data.user_type  == 'admin'"
          icon="mdi-pencil"
          text="Edit Contact"
          flat
          @click="editAdminContact()"
        />
        <ButtonWithTooltip
          v-if="data.deleted_at == null"
          icon="mdi-trash-can"
          text="Delete"
          flat
          @click="deleteUser(data)"
        />
        <ButtonWithTooltip
          v-if="data.active_at"
          :disabled="data.deleted_at"
          icon="mdi-account-minus"
          text="Disable Login"
          flat
          @click="disableActive(data)"
        />
        <ButtonWithTooltip
          v-if="!data.active_at"
          :disabled="data.deleted_at"
          icon="mdi-account-plus"
          text="Enable Login"
          flat
          @click="enableActive(data)"
        />
        <ButtonWithTooltip
            :disabled="!data.twofactor_enabled_at"
            icon="mdi-minus-circle"
            text="Disable 2FA"
            tooltip="Disable two-factor authentication"
            flat
            @click="showDisable2FADialog=true"
        />
        <ButtonWithTooltip
            icon="mdi-lock-reset"
            text="Reset Password"
            tooltip="Reset user's password"
            flat
            @click="showPasswordResetDialog=true"
        />
      </template>
      <SimpleDialog @click="resetPassword(data)" title="Reset Password"  v-model="showPasswordResetDialog" :loading="busy">
        <p>This process will send the user a Password reset email.</p>
        <p>Do you wish to proceed?</p>
      </SimpleDialog>
      <SimpleDialog @click="showPasswordResetSuccessDialog=false" okOnly title="Reset Password successful"  v-model="showPasswordResetSuccessDialog">
        <p>Process completed. Reset password email has been sent to the user.</p>
      </SimpleDialog>

      <SimpleDialog @click="disable2FA(data)" title="Disable 2FA"  v-model="showDisable2FADialog" :loading="busy" yesColor="error">
        <p>This process will disabled the user's 2FA security.</p>
        <p>Do you wish to proceed?</p>
      </SimpleDialog>
      <SimpleDialog @click="showDisable2FASuccessDialog=false" okOnly title="Disable 2FA"  v-model="showDisable2FASuccessDialog">
        <p>Process completed. 2FA disabled successfully.</p>
      </SimpleDialog>
      <AddContactDialog
        :title="'Edit Admin User ('+data.username+') Contact details'"
        v-model="showEditAdminContactDialog"
        :contactData="adminContactPrefill"
      >
      </AddContactDialog>
      <ConvertToUserDialog v-if="data.user_type == 'admin' && adminUserPrefill != null" v-model="showEditAdminUserDialog" :contactData="adminContactPrefill" :userData="adminUserPrefill" :title="'Update User Details'"/>
    </BasicCard>
</template>

<script>
import BasicCard from '@/components/cards/templates/BasicCard';
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip";
import SimpleDialog from "../../dialogs/templates/SimpleDialog";
import apiMixin from "../../../mixins/apiMixin";
import dialogMixin from "../../../mixins/dialogMixin";
import AddContactDialog from "../../dialogs/AddContactDialog.vue";
import ConvertToUserDialog from "../../dialogs/ConvertToUserDialog.vue";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
export default {
    name: 'UserCard',
    components: { ConvertToUserDialog, AddContactDialog, SimpleDialog, ButtonWithTooltip, BasicCard },
    mixins: [apiMixin, dialogMixin,GlobalHelperMixin],
    data: () => ({
      showPasswordResetDialog: false,
      showPasswordResetSuccessDialog: false,
      showDisable2FASuccessDialog: false,
      showDisable2FADialog: false,
      showEditAdminContactDialog: false,
      showEditAdminUserDialog: false,
      busy: false,
      adminContactPrefill:null,
      adminUserPrefill:null,
    }),
    props: {
        data: {
            type: Object,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
      isSuperAdmin: function(){
        return this.$store.state.user.user_type == 'admin' && this.$store.state.user.user_roles && this.$store.state.user.user_roles.includes('super-admin');
      }
    },
    methods: {
      titleCase(string) {
        let sentence = string.toLowerCase().split("_");
        for (let i = 0; i < sentence.length; i++) {
          sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
        }
        return sentence.join(" ");
      },
      async resetPassword(data)
      {
        this.showPasswordResetDialog = false;
        this.busy = true;
        let response = await this.Api.send('put', 'users/' + this.data.id + '/reset-password');

        if (response.success) {
          document.dispatchEvent(new CustomEvent('refreshRequested'));
          this.showPasswordResetSuccessDialog = true;
        }
        this.busy = false;
      },
      async disable2FA(data)
      {
        this.showDisable2FADialog = false;
        this.busy = true;
        let response = await this.Api.send('put', 'users/' + this.data.id + '/twofactor/disable');
        if (response.success) {
          document.dispatchEvent(new CustomEvent('refreshRequested'));
          this.showDisable2FASuccessDialog = true;
        }
        this.busy = false;
      },
      async disableActive() {
        this.busy = true;
        let response = await this.Api.send('put', 'users/' + this.data.id+ '/disable-login',[]);
        if (response.success) {
          document.dispatchEvent(new CustomEvent('refreshRequested'));
        }
        this.busy = false;
      },
      async enableActive() {
        this.busy = true;
        let response = await this.Api.send('put', 'users/' + this.data.id+ '/activate-login',[]);
        if (response.success) {
          document.dispatchEvent(new CustomEvent('refreshRequested'));
        }
        this.busy = false;
      },
      async deleteUser() {
        this.busy = true;
        let response = await this.Api.send('delete', 'admin/users/' + this.data.id,[]);
        if (response.success) {
          document.dispatchEvent(new CustomEvent('refreshRequested'));
        }
        this.busy = false;
      },
      setupDataForEdits(){
        this.adminContactPrefill = {
          'id':this.data.contact_id,
          'salutation':this.data.salutation,
          'firstname':this.data.firstname,
          'lastname':this.data.lastname,
          'phone1':this.data.phone1,
          'phone2':this.data.phone2,
          'email':this.data.email,
        };
        this.adminUserPrefill = {
          'username':this.data.username,
          'id':this.data.id,
        }
      },
      editAdminContact(){
        this.setupDataForEdits();
        this.showEditAdminContactDialog = true;
      },
      editAdminUser(){
        this.setupDataForEdits();
        this.showEditAdminUserDialog = true;
      },
      async addSuperAdmin(){
        this.busy = true;
        let response = await this.Api.send('put', 'admin/users/admin/' + this.data.id+ '/user-roles',{'user_roles':['super-admin']});
        if (response.success) {
          document.dispatchEvent(new CustomEvent('refreshRequested'));
        }
        this.busy = false;
      },
      async removeSuperAdmin(){
        this.busy = true;
        let response = await this.Api.send('put', 'admin/users/admin/' + this.data.id+ '/user-roles',{'user_roles':[]});
        if (response.success) {
          document.dispatchEvent(new CustomEvent('refreshRequested'));
        }
        this.busy = false;
      }
    }
};
</script>

<style scoped></style>
