<template>
  <div>
    <v-row>
      <v-col sm="4">
        <IPNDCard :cardType="portaAccountData!=null?'trunk':'ipnd'" :ipndData="ipndData" :porta-account-data="portaAccountData" :loading="isBusy" v-if="ipndData != null"/>
        <BasicCard :loading="isBusy" v-if="ipndData == null">
          <p>Failed to load data.</p>
          <!-- This is a basic card just to show the loading animation -->
        </BasicCard>
      </v-col>
      <v-col sm="4" >
        <TrunkCard
          v-if="portaAccountData != null"
          :loading="isBusy"
          :data="portaAccountData"
          cardUsage="external"
        />
        <BasicCard :loading="true" v-if="portaAccountData == null && isBusy">
        </BasicCard>
        <BasicCard :loading="false" v-if="portaAccountData == null && !isBusy"
                   v-bind:class="{
            'error lighten-4': (!$vuetify.theme.dark),
            'error darken-4': ($vuetify.theme.dark),
        }"
        >
          <template v-slot:feature>
            <v-icon x-large color="error">mdi-alert-circle</v-icon>
          </template>
          <p class="title text--primary">Linked Porta Account</p>
          <p v-if="ipndData && ipndData.porta_account_error != null">{{ipndData.porta_account_error}}</p>
        </BasicCard>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="ipndData != null && portaAccountData != null">
        <DIDListCard title="DIDs using this address" :trunkData="portaAccountData" v-if="ipndData != null && portaAccountData != null" :filter="{'page':1,vendorNumber:Object.keys(ipndData.ids)[0], ipndId: ipndData.ids[Object.keys(ipndData.ids)[0]][0],'i_account':portaAccountData.i_account,'i_customer':portaAccountData.i_customer}"/>
        <BasicCard :loading="true" v-if="ipndData == null"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import IPNDCard from "../../cards/IPND/IPNDCard.vue";
import TrunkCard from "../../cards/Trunk/TrunkCard.vue";
import DIDListCard from "../../cards/DID/DIDListCard.vue";
import BasicCard from "../../cards/templates/BasicCard.vue";
import refreshListenerMixin from "../../../mixins/refreshListenerMixin";
export default {
    name: 'IPNDDetailsComponent',
    components: { BasicCard, DIDListCard, TrunkCard, IPNDCard },
    mixins: [refreshListenerMixin],
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      ipndId:{
        type: Number,
        default: null
      },
      /*
       * vendor number
       * We use a number to mask the vendor.
       */
      vendorNumber:{
        type: Number,
        default: null,
      }
  },
    data: () => ({
      busy:false,
      ipndData:null,
      portaAccountData:null,
    }),
    methods: {
      async update( ) {
        this.busy = true;
        const response = await this.Api.send('get','ipnds/'+ this.vendorNumber + '/' + this.ipndId);
        this.busy = false;
        if(response.success){
          this.ipndData = response.data;
          if ('i_account' in this.ipndData && this.ipndData.i_account != null) {
            this.getPortaAccount(this.ipndData.i_account);
          }
        }else{
          this.ipndData = null;
        }
      },
      async getPortaAccount(i_account) {
        this.busy = true;
        const response = await this.Api.send('get','trunks/' + i_account);
        this.busy = false;
        if(response.success){
          this.portaAccountData = response.data;
        }else{
          this.portaAccountData = null;
        }
        this.buildBreadCrumbs();
      },
      closeModal(refresh) {
        this.showDialog = false;
        if (refresh) {
            this.$emit('update');
        }
      },
      buildBreadCrumbs( ){
        let breadCrumbs = [
          {
            name:"TrunkIPNDList",
            label: "Emergency Service Addresses",
            params:{"customerId":this.portaAccountData.i_customer,"trunkId":this.portaAccountData.i_account}
          }
        ];
        this.$store.commit('data', {name: 'breadCrumbsAddon', data: breadCrumbs});
      }
    },
  computed: {
    isBusy: function( ){
      return this.loading || this.busy;
    },
  },
  watch: {
    ipndId(value) {
      this.update( );
    }
  },
  created: function( ) {
    if (this.ipndId != null) {
      this.update();
    }
  },
};
</script>
