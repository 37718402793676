<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <v-card>
                    <v-card-actions>
                        <v-card-text class="pa-2">Filters</v-card-text>

                        <v-card-text class="pa-2 float-right text-right">
                            Show:
                            <v-menu offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-btn small v-on="on" class="ml-2">
                                        {{ filter.status.option }}
                                        <v-icon>mdi-chevron-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list>
                                        <v-list-item
                                            v-for="(
                                                number, index
                                            ) in filter.statusOptions"
                                            :key="index"
                                            @click="updateStatus(number)"
                                        >
                                            <v-list-item-title>{{
                                                number.option
                                            }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-list>
                            </v-menu>
                        </v-card-text>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12">
                <PaginationCard
                    :page="filter.page"
                    :data="data"
                    :limit="filter.limit"
                    :loading="loading"
                    v-on:updateLimit="updateLimit"
                    v-on:updatePage="updatePage"
                >
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th
                                        v-if="$vuetify.breakpoint.smAndUp"
                                        class="text-left"
                                    >
                                        ID
                                    </th>
                                    <th class="text-left">Business Name</th>
                                    <th
                                        v-if="$vuetify.breakpoint.smAndUp"
                                        class="text-left"
                                    >
                                        Dealer Code
                                    </th>
                                    <th class="text-left">Contact Person</th>

                                    <th class="text-center">Status</th>
                                    <th
                                        v-if="$vuetify.breakpoint.smAndUp"
                                        class="text-left"
                                    >
                                        Submitted
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <router-link
                                    tag="tr"
                                    v-for="(item, i) in data.data"
                                    :key="i"
                                    link
                                    :to="'/admin/signups/' + item.signup_id"
                                    style="cursor: pointer"
                                >
                                    <td v-if="$vuetify.breakpoint.smAndUp">
                                        {{ item.signup_id }}
                                    </td>
                                    <td>
                                        <span class="font-weight-medium">{{
                                            item.companyname
                                        }}</span>
                                    </td>
                                    <td v-if="$vuetify.breakpoint.smAndUp">
                                        {{ item.refnum }}
                                    </td>
                                    <td>
                                        {{ item.firstname }} {{ item.lastname }}
                                    </td>
                                    <td class="text-center">
                                        <SignupStatus :data="item" />
                                    </td>
                                    <td v-if="$vuetify.breakpoint.smAndUp">
                                        <LocalDateTime
                                            :value="item.submitted"
                                            relative
                                        />
                                    </td>
                                </router-link>
                            </tbody>
                        </template>
                    </v-simple-table>
                </PaginationCard>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PaginationCard from '@/components/cards/templates/PaginationCard';
import LocalDateTime from '@/components/pieces/LocalDateTime';
import SignupStatus from '@/components/pieces/Signup/SignupStatus';
import apiMixin from '../../../mixins/apiMixin';
import IPNDDetailsComponent from "../../../components/pieces/IPND/IPNDDetailsComponent.vue";
export default {
    name: 'SignupList',
    mixins: [apiMixin],
    data: () => ({
        filter: {
            limit: 25,
            page: 1,
            statusOptions: [
                { option: 'All', value: null },
                { option: 'Active', value: 'ACTIVE' },
                { option: 'Pending Review', value: 'NEW' },
                { option: 'Agreement Sent', value: 'SENT' },
                { option: 'Agreement Signed', value: 'SIGNED' },
                { option: 'Pending Creation', value: 'PENDING' },
                { option: 'Completed', value: 'COMPLETED' },
                { option: 'Rejected', value: 'REJECTED' },
            ],
            status: { option: 'Active', value: 'ACTIVE' },
        },
        dialog: {
            new: {
                visible: false,
            },
        },
        loading: false,
        data: [],
    }),
    components: {
        SignupStatus,
        LocalDateTime,
        PaginationCard
    },
    methods: {
        async update() {
            this.loading = true;
            let params = { limit: this.filter.limit, page: this.filter.page };
            if (this.filter.status.value !== null) {
                params.status = this.filter.status.value;
            }
            const response = await this.Api.send('post','signup/list', params);
            this.loading = false;
            if (response.success) {
                this.updateData(response.data);
            } else {
                this.updateFailed(response.error, response.errorDetail);
            }
        },
        updatePage(data) {
            this.filter.page = data;
            this.update();
        },
        updateLimit(data) {
            this.filter.limit = data;
            this.filter.page = 1;
            this.update();
        },
        updateHidden(data) {
            this.filter.hidden = data;
            this.filter.page = 1;
            this.update();
        },
        updateData(data) {
            this.data = data;
        },
        updateStatus(data) {
            this.filter.status = data;
            this.filter.page = 1;
            this.update();
        },
        updateFailed() {
            this.data = [];
        },
    },
    created() {
        this.update();
    },
};
</script>
