<template>
    <v-row dense>
        <v-col cols="12" v-if="partnerLoading || busy" class="text-center">
          <BasicCard class="pa-2">
            <v-progress-circular indeterminate /> Loading...
          </BasicCard>
        </v-col>
        <v-col v-for="contact in data.data" :key="contact.id" cols="12" sm="6" lg="4">
            <ContactCard :data="contact" createUser :partnerId="partnerData.i_rep" @edit="editContact" :allowEdit="true"/>
        </v-col>
        <FloatingButton tooltip=  "New Contact" icon="mdi-plus" @click="createContact" :loading="partnerLoading" />
        <AddContactDialog v-model="showNewContact" :title="((prefill == null || prefill.id == null) ? 'Add New Partner Contact' : 'Edit Partner Contact details')" :customerId="data.id" :partnerId="this.partnerData.i_rep" :contactData="prefill"/>
    </v-row>
</template>

<script>
import partnerDataMixin from '../../mixins/partnerDataMixin';
import ContactCard from '../../components/cards/ContactCard';
import FloatingButton from '@/components/pieces/FloatingButton';
import AddContactDialog from '@/components/dialogs/AddContactDialog';
import apiMixin from '@/mixins/apiMixin';
import refreshListenerMixin from '@/mixins/refreshListenerMixin';
import BasicCard from '@/components/cards/templates/BasicCard';
export default {
    name: 'PartnerContacts',
    components: { BasicCard, AddContactDialog, FloatingButton, ContactCard },
    mixins: [apiMixin, partnerDataMixin, refreshListenerMixin],
    data: () => ({
        showNewContact: false,
        data: [],
        busy: false,
        prefill: null,
    }),
    watch: {
        partnerLoading(value) {
            this.update();
        }
    },
    methods: {
        async update() {
            if (!this.partnerData || !this.partnerData.i_rep) return;
                this.busy = true;
                const response = await this.Api.send('post', (this.$store.state.user.isAdmin ? 'partners/' + this.partnerData.i_rep + '/get_contacts' : 'partner/get_contacts'));
                this.data = response.success ? response.data : null;
                this.busy = false;
        },
        refreshRequested() {
          this.update();
        },
        editContact(data) {
          this.prefill = data;
          this.prefill.title = data.salutation;
          this.showNewContact = true;
        },
      createContact() {
        this.prefill = null;
        this.showNewContact = true;
      }
    },
    created() {
        this.update();
    }
};
</script>