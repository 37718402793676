<template>
    <v-container fluid>
      <IPNDDetailsComponent
        :ipnd-id="parseInt(this.$route.params.ipndId)"
        :vendor-number="parseInt(this.$route.params.vendorNumber)"
      />
    </v-container>
</template>
<script>

import IPNDDetailsComponent from "../../components/pieces/IPND/IPNDDetailsComponent.vue";

export default {
    name: 'IPNDDetailsPage',
    components: {
      IPNDDetailsComponent
    },
};
</script>
